import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PaginationEventBus } from "@/core/lib/pagination.lib";
export default {
  props: {
    dataLoading: {
      type: Boolean
    },
    currentPage: {
      type: Number
    },
    totalPages: {
      type: Number
    },
    showingFrom: {
      type: Number
    },
    showingTo: {
      type: Number
    },
    totalRows: {
      type: Number
    }
  },
  data: function data() {
    return {
      currentModelPage: 0
    };
  },
  methods: {
    updatePagination: function updatePagination() {
      PaginationEventBus.$emit("update:pagination", this.currentModelPage);
    }
  },
  mounted: function mounted() {
    this.currentModelPage = this.currentPage;
  }
};